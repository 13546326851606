import React, { useContext } from "react";
import NewsCard from "../../components/NewsCard";
import SectionHeader from "../../components/Headers/SectionHeader";
import { NewsDataContext } from "../../context";

const News = () => {
  const news = useContext(NewsDataContext);

  return (
    <section id="news">
      <SectionHeader text="Haberler" />
      {news?.edges.length === 0 ? (
        <p className="handwriting empty_notifier">Çok yakında sizlerle...</p>
      ) : (
        <>
          {news?.edges.map(({ node }) => {
            return (
              <NewsCard
                key={node.slug}
                slug={node.slug}
                title={node.haber_basligi}
                content={node.haber_icerigi}
                date={node.haber_yazilma_tarihi}
                featuredImage={node?.haber_resmi?.childImageSharp?.gatsbyImageData}
              />
            );
          })}
        </>
      )}
    </section>
  );
};

export default News;
